/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

*{
  -webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;padding:0;margin:0;}

html {
  -webkit-text-size-adjust: 100%;
}


/*  SASS ***************************************************** */


$color-button-orange: #FF7200;
$color-button-gray: #666666;
$color-background: #f3f3f3; // rgb(240 , 240 , 240)
$vertical-row-gap: 20px;
$pad-side: 70px;
$pad-capture-bottom: 45px;
$radius-big: 20px;
$radius-medium: 10px;
$radius-small: 5px;
$radius-circle: 50%;
$shadow-big: 0px 10px 10px 10px #00000033;

$app-header-height: 64px;
$app-container-height: calc(100% - #{$app-header-height});




%vh100{
  width: 100vw;
  height: 100vh;
}



/* Capas al 100% */
%overlay100{
  @extend %vh100;
  position: absolute;
  top: 0;
  left: 0;
}



%rtl{
  direction: rtl;
  //unicode-bidi: bidi-override !important
  // font-family: Ravi
}


%ltr{
  direction: ltr;
  //unicode-bidi: bidi-override !important
  // font-family: Base
}









/* ***************************************************** GLOBAL ***************************************************** */

html{
  // font-family: helvetica
}


body{
  margin: 0;
  line-break: auto;
  @extend %vh100;
}


*{
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/*

::-webkit-scrollbar{
  display: none;
}

*/


.editor{
  cursor: auto !important;
  touch-action: auto;
  user-select: auto;
}


.touchscreen{
  cursor: none;
  touch-action: none;
  user-select: none;
}




.flex{
  display: flex;
}


.flex-container-row{
  @extend .flex;
  flex-direction: row;
}


.flex-container-col{
  @extend .flex;
  flex-direction: column;
}


.stretchToFit{
  flex: auto;
}



.j-sb{
  justify-content: space-between;
}

.j-s{
  justify-content: flex-start;
}

.j-c{
  justify-content: center;
}

.a-c{
  align-items: center;
}


/* ***************************************************** DIRECTION ***************************************************** */

.ltr{
  @extend %ltr;
  // font-family: EnglishFont;
  // vertical-align: text-bottom;
  // line-height: 1.0;
}


.rtl{
  @extend %rtl;
  // font-family: ArabicFont;
}


.en{
  @extend .ltr;
}

.es{
  @extend .ltr;
}

.ar{
  @extend .rtl;
}




/* ***************************************************** MODAL ***************************************************** */
.overlay100{
  @extend %overlay100;
}

.dark{
  background-color: rgba(0 , 0 , 0 , 0.8);
}

.modal{
  @extend %overlay100;
  position: fixed;
  z-index: 1  ;
}

.middle-container{
  display: flex;
  align-items: center;
  justify-content: center  ;
}


.middle-center{
  align-self: center;
  margin: auto;

}

.h-spacer{
  flex: 1 1 auto;
}

.bold{
  font-weight: bold;
}

.unbold{
  font-weight: normal;
}

.upper{
  text-transform: uppercase;
}

.a-left{
  text-align: left !important;
}

.a-right{
  text-align: right !important;
}

.a-center{
  text-align: center !important;
}


.w100pc{
  width: 100%;
}

.w50pc{
  width: 50%;
}

.w25pc{
  width: 25%;
}

.h100pc{
  height: 100%;
}
.h50pc{
  height: 50%;
}

.h600{
  height: 472px;
}

.w-50{
  width: 50px;
}

.w-100{
  width: 100px;
}

.w-220{
  width: 220px;
}

.w-300{
  width: 300px;
}

.w-60{
  width: 60px  ;
}




/* ***************************************************** INTERACTION ***************************************************** */
.interactive{
  pointer-events: auto;
}

.un-interactive{
  pointer-events: none;
}


.clickable{
  cursor: pointer;
}

.un-clickable{
  cursor: not-allowed;
}

.visible{
  visibility: inherit;
}

.invisible{
  visibility: hidden;
}

.icon-clickable{
  @extend .clickable;
  filter: unset;
}

.icon-unclickable{
  @extend .un-clickable;
  pointer-events: none;
  filter: opacity(0.5) grayscale(1);
}



/* ***************************************************** SCREEN ***************************************************** */

/*
.screen-app{
  background-color: $color-background;
  height: $admin-height;
}

.screen-title{
  text-align: center;
  font-weight: bolder;
  text-transform: uppercase;
  font-size: 55px;

}

.screen-subtitle{
  @extend .screen-title;
  font-weight: inherit;
  font-size: 28px !important;

}


.screen-container{
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: $pad-side;

}



.screen-button{
  color: white;
  padding: 12px;
  border-radius: $radius-medium;
  font-weight: normal;
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  background-color: $color-button-orange;

}
*/




/*  LANGUAGE ***************************************************** */
.language_container{
  position: fixed;
  right: 0;
  top: 50% ;
}

.language_tab{
  margin-top: -50%;
  padding: 12px;
  text-align: right;
  font-weight: bold;
  background-color: white;
  border-top-left-radius: $radius-medium;
  border-bottom-left-radius: $radius-medium;
  box-shadow: 0px 5px 5px 5px #00000066;
  div{
    padding-top: 5px;
    padding-bottom: 5px;
    &.selected{
      color: $color-button-orange;
    }
    &.unselected{
      color: inherit  ;
    }
  }
  div:not(:last-child){
    border-bottom: 1px solid #000;
  }

}


.language_selected{
  color: chartreuse;
}

.language_unselected{
  color: black;
}


/* APP  **************************************************** */
iridik-root{
  @extend %vh100;
}

.adminContainer{
  // @extend .flex-container-col;
  //width: 100vw;
  //height: $app-container-height;
  width: 100%;
  height: 100%;
  font-family: helvetica;
}


.app-container{
  @extend .adminContainer;
  width: 100vw;
  height: $app-container-height;
}



/* ADMIN  **************************************************** */

.component-container{
  @extend .flex-container-col;
  @extend .adminContainer;

  h1{
    text-align: center;
  }

  .title{
    text-align: center;
  }

  .description{
    text-align: center;
  }

  .content{
    overflow-y: scroll;
    padding-top: 20px;
    min-width: 50vw;
    min-height: 220px;

    .card{
      @extend .middle-center;
      padding: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 220px;
      &:not(:first-child){
        border-top: 1px solid gray;
      }
      h2{
        @extend .a-center ;
        padding-top: 20px;
        padding-bottom: 20px;
      }
      mat-label{
        @extend .flex-container-row ;
        margin-bottom: 10px;
      }
    }
  }
}




/* LOGIN ///////////////////////////////////////////////// */
iridik-login{
  @extend %vh100;
}

.loginContainer{
  display: flex;
  flex-direction: row;
  >div{
    display: flex;
    flex-direction: column;
    padding: 50px;
    div{
      @extend .flex , .middle-center;
    }
  }
}


.loginButton{
  @extend .a-center;
  border-right: 1px solid gray;
  >*{
    padding-top: 20px;
  }
}





/* LOGIN ///////////////////////////////////////////////// */
iridik-spectacle{
  @extend %vh100;
}

.spectacleCard {
  @extend .middle-center;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 220px;
  &:not(:first-child){
    border-top: 1px solid gray;
  }
  .spectacleDates{
    padding-bottom: 20px;
    div{
      @extend .flex-container-row;
    }
  }
}



/* LOCATION ///////////////////////////////////////////////// */
iridik-location{
  @extend %vh100;
}

/* ASSETS ///////////////////////////////////////////////// */
iridik-asset{
  @extend %vh100;
}

/* LATENCY ///////////////////////////////////////////////// */
iridik-latency{
  @extend %vh100;
}

/* CLIENT ///////////////////////////////////////////////// */
iridik-client{
  @extend %vh100;
}

/* PLAYER ///////////////////////////////////////////////// */
iridik-player{
  @extend %vh100;
}



.animationInside{
  width: 100%;
}


.screenAnimationContainer{
  *{
    @extend .animationInside;
  }
}

mat-divider{
  &.thin{
    margin-top: 5px;
    margin-bottom: 5px;
  }
  &.thick{
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.screenStackContainer {
  display: block;
  position: relative;
  background-color: #666666;
}



.screenStackLayer{
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.qr-marker{
  display: block;
  position: absolute;
  div{
    display: block;
    position: absolute;
  }
}

.inverted {
  mix-blend-mode: difference;
}


.section-variables {
  display: flex;
  align-content: center;
  align-items: center;
  height: 30px;
}


.notInteractive {
  cursor: not-allowed;
}

.admin-section{
  display: flex;
  align-content: center;
  align-items: center;
  height: 40px;
}
.admin-section-top{
  display: flex;
  align-content: center;
  //align-items: center;
  //height: 40px;
}

.mr-8 {
  margin-right: 8px !important;
}


.admin-side-textarea{
  flex: auto;
  resize: vertical;
}



/*

.mat-tab-group{
  height: 100%;
}

.mat-tab-body-wrapper{
  height: 100%;
}
*/






.editor-tab-group{

  mat-tab-header{
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    z-index: 2;
  }

}

.editor-dual{
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow-y: scroll;

  .list{
    height: 100%;
    min-width: 300px;
    border-right: 1px solid grey ;
  }

  .editor{
    @extend .h-spacer;
    height: 100%;
  }
}



.editor-form{
  @extend .flex-container-col;
  padding: 20px;
}

.editor-tab-content{
  @extend .flex-container-col;
}

.editor-tab-content{
  @extend .flex-container-col;
}
.editor-tab-section{
  @extend .h-spacer;
  @extend .flex-container-row;
  margin-bottom: 20px;
  align-items: center;

}

.editor-label{
  display: inline-block;
  min-width: 120px;
  padding: 20px;
}

.editor-field{
  display: inline-block;
  padding: 20px;
  min-width: 40%;
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
  input, textarea{
    padding: 20px;
    width: 100%;
  }
  &.list{
    display: block;
    min-width: 40%;
    div{
      margin-top: 10px;
      input{
        margin-top: 10px;
      }
    }
  }

}

.editor-extra{
  @extend .h-spacer;
  padding: 20px;
  display: inline-block;
  a{
    color: blue;
    text-decoration: underline;
  }
  p{
    margin-bottom: 20px;
  }
}


.toolbar-button{
  display: inline-block;
  &.save{
    //
  }
  &.help{
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
}


.editor-button{
  display: inline-block;
  margin: 10px !important;
}

.editor-divider{
  margin: 20px 0;
}

mat-expansion-panel{
  *{
    position: relative;
  }
}

.item-editor-toolbar{
  button {
    margin: 0px 10px;
  }
}


.mat-list-text{
  padding-right: 0px !important;
}

